import React, { createContext, useReducer, useContext } from "react"
import reducer from "./reducer"
import { storage } from "@/util"

export const initState = {
  lToken: storage.getl("lToken"),
  lInfo: storage.getl("lInfo"),
  lParameter: storage.getl("lParameter"),
  lHomeSummary: storage.getl("lHomeSummary"),
  lBotSummary: storage.getl("lBotSummary"),
  lRewardSummary: storage.getl("lRewardSummary"),
  lBinayPlan: storage.getl("lBinayPlan"),
  lVersion: storage.getl("lVersion"),
}

const ConfigCtx = createContext(null)

export const Provider = props => {
  const [lState, lDispatch] = useReducer(reducer, initState)
  return <ConfigCtx.Provider value={{ lState, lDispatch }}>{ props.children }</ConfigCtx.Provider>
}

export const useLocalStore = () => useContext(ConfigCtx)