/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react"
import { Popup } from "antd-mobile"
import { useConstant, useRoute, useClass, useRequest } from "@/hook"
import { useTranslation } from "react-i18next"
import { IMAGE } from "@/assets"
import "./styles.less"

const IndexComponent = React.memo((props) => {
  const { title, type, bgColor, noBack } = props
  const { classNames } = useClass()
  const { back } = useRoute()
  const { NAVBARTYPE } = useConstant()

  return (
    <div className={classNames([
      "m_navbar",
      type === NAVBARTYPE.light && "m_navbar_light",
      type === NAVBARTYPE.dark && "m_navbar_dark"
    ])} style={{ backgroundColor: bgColor }}>
      {
        !noBack && <img src={type === NAVBARTYPE.light ? IMAGE.arrow_left_black : IMAGE.arrow_left_white} alt="" className="m_navbar_back" onClick={() => back()} />
      }
      <p className={classNames([
        "m_navbar_title",
        type === NAVBARTYPE.light && "m_navbar_title_dark",
        type === NAVBARTYPE.dark && "m_navbar_title_light"
      ])}>{ title || "" }</p>
    </div>
  )
})

const HomeIndexComponent = React.memo(() => {
  const { i18n } = useTranslation()
  const { request } = useRequest()
  const [visible, setVisible] = useState(false)
  const [message, setMessage] = useState(null)

  useEffect(() => {
    const getMessage = async () => {
      const lang = i18n.language.startsWith("zh") ? "zh" : "en"
      const resp = await request("app/home/announcement/latest", {
        lang: lang
      })
      if (!resp?.error) {
        setMessage(resp)
        if (!resp.isRead) {
          setVisible(true)
        }
      }
    }
    getMessage()
  }, [i18n])

  return (
    <div className="m_home_navbar">
      <img src={IMAGE.logo_name} alt="" className="m_home_navbar_logo" />
      <div style={{ flex: "1" }} />
      {
        !!message && <div className="m_home_navbar_message" onClick={() => setVisible(true)}>
        <img src={IMAGE.message} alt="" />
      </div>
      }
      <Popup
        visible={visible}
        onMaskClick={() => setVisible(false)}
        onClose={() => setVisible(false)}
        bodyClassName="m_home_navbar_popup"
      >
        <img src={IMAGE.close} alt="" onClick={() => setVisible(false)} />
        <p className="title">{ message?.title }</p>
        <p className="content">{ message?.content }</p>
      </Popup>
    </div>
  )
})

export default IndexComponent

export {
  HomeIndexComponent
}