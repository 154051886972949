import { useState } from "react"
import { mathematics } from "@/util"
import { useTranslation } from "react-i18next"
import { Button, ProgressBar } from "antd-mobile"
import { useRequest, useClass, useConstant } from "@/hook"
import { useLocalStore } from "@/store"
import { IMAGE } from "@/assets"
import moment from "moment"
import "./styles.less"

const IndexComponent = () => {
  const { t } = useTranslation()
  const { lState, lDispatch } = useLocalStore()
  const { COUPONREWARDS } = useConstant()
  const { request } = useRequest()
  const { classNames } = useClass()
  const { lHomeSummary, lInfo } = lState
  const [loading, setLoading] = useState(false)

  const checkin = async () => {
    if (lHomeSummary?.uptime?.status === "ONLINE") return
    setLoading(true)
    const resp = await request("app/home/coupon/checkin")
    setLoading(false)
    if (!resp?.error) {
      lDispatch({key: "lHomeSummary", value: {
        ...lHomeSummary, 
        uptime: {
          ...lHomeSummary?.uptime,
          status: "ONLINE"
        }
      }})
      lDispatch({key: "lInfo", value: {...lInfo, hasCheckedInToday: true}})
    }
  }

  return (
    <div className="m_co">
      <div className="m_connect">
        <img src={IMAGE.wifi} alt="" className="m_connect_icon" />
        <div className="m_connect_ip_info">
          <p>{
            `IP: ${
              lHomeSummary?.uptime?.status === "ONLINE" ? 
                !!lHomeSummary?.uptime?.ip ?
                  lHomeSummary?.uptime?.ip 
                : "~" 
              : "~"}`
          }</p>
          <p>{
            `${t("component.connect.t1")}: ${
              lHomeSummary?.uptime?.status === "ONLINE" ? 
                !!lHomeSummary?.uptime?.uptime ?
                  mathematics.floorString(moment.duration(lHomeSummary?.uptime?.uptime).asHours(), 1)
                : 
                  0 
              : 0} ${t("component.connect.t2")} · ${
                lHomeSummary?.uptime?.status === "ONLINE" ? 
                  !!lHomeSummary?.uptime?.uptime && !!lHomeSummary?.uptime?.totalTime ?
                    mathematics.floorString((lHomeSummary?.uptime?.uptime / lHomeSummary?.uptime?.totalTime) * 100, 1)
                  :
                    0
                :
                  0
              }%`
          }</p>
        </div>
        <Button 
          loading={loading}
          className={classNames([
            "m_connect_connect",
            lHomeSummary?.uptime?.status === "ONLINE" && "m_connect_connected",
          ])}
          onClick={checkin}
        >
          {
            lHomeSummary?.uptime?.status === "ONLINE" ? 
              <div className="connect_ed" />
            : 
              <img src={IMAGE.connect} alt="" className="connect_icon" />
          }
          <p>{ lHomeSummary?.uptime?.status === "ONLINE" ? t("component.connect.t3") : t("component.connect.t4") }</p>
        </Button>
      </div>
      <div className="m_coupon">
        <div className="m_coupon_head">
          <p className="head_name">{t("component.connect.t5")}</p>
          <img src={IMAGE.coupon_2} alt="" className="head_icon" />
          <p className="head_amount">{mathematics.localString(lHomeSummary?.couponYield)} <span>{`/ ${mathematics.localString(COUPONREWARDS)}`}</span></p>
        </div>
        <ProgressBar percent={
          !lHomeSummary || Number(lHomeSummary?.couponYield) === 0 ?
            0
          :
            Math.max((Number(lHomeSummary?.couponYield) / Number(COUPONREWARDS)) * 100, 0.5)
        } className="m_coupon_process_bar" />
      </div>
    </div>
  )
}

export default IndexComponent