/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import { Button } from "antd-mobile"
import { mathematics } from "@/util"
import { useTranslation } from "react-i18next"
import { useClass, useRequest } from "@/hook"
import { MNodata } from "@/component"
import { IMAGE } from "@/assets"
import "./styles.less"

const PAGESIZE = 10

const IndexComponent = () => {
  const { t } = useTranslation()
  const { classNames } = useClass()
  const { request } = useRequest()
  const [page, setPage] = useState(1)
  const [data, setData] = useState(null)
  const [last, setLast] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const getData = async () => {
      setLoading(true)
      const resp = await request("app/home/uptime", {
        page: page,
        pageSize: PAGESIZE,
      })
      setLoading(false)
      if (!resp?.error) {
        const _last = resp.length < PAGESIZE ? true : false
        setLast(_last)
        const _resp = page === 1 ? resp : [...data, ...resp]
        setData(_resp) 
      }
    }
    getData()
  }, [page])

  return (
    <div className="m_network">
      <p className="m_network_title">{ t("component.network.t1") }</p>
      <div className="m_network_tab">
        <div className="m_network_tab_item">
          <img src={IMAGE.name} alt="" />
          <p>{ t("component.network.t2") }</p>
        </div>
        <div className="m_network_tab_item">
          <img src={IMAGE.wifi2} alt="" />
        </div>
        <div className="m_network_tab_item">
          <p>{ t("component.network.t3") }</p>
          <img src={IMAGE.time} alt="" />
        </div>
      </div>
      {
        !!data ?
          data.length > 0 ? 
            <>
              <div className="m_network_list">
              {
                data.map((item, index) => {
                  return <div className="network_item" key={index}>
                    <p>
                      <span className={classNames([
                        item.status === "ONLINE" && "active"
                      ])} />
                      { item.username }
                    </p>
                    <p>{ item.status === "ONLINE" ? item.ip : t("component.network.t4") }</p>
                    <p>{
                      !!item.uptime && !!item.totalTime ?
                        `${mathematics.floorString((item.uptime / item.totalTime) * 100, 0)}%`
                      :
                        "N/A"
                    }</p>
                  </div>
                })
              }
              </div>
              {
                !last && <Button 
                  loading={loading}
                  className="m_network_more" 
                  onClick={() => setPage(page + 1)}
                >
                  <img src={IMAGE.arrow_bottom_black2} alt="" />
                  { t("component.network.t6") }
                </Button>
              }
            </>
          :
            <MNodata 
              noneTitle
              icon={IMAGE.empty_1} 
              buttonName={t("component.network.t5")} 
              route="share" 
            />
        :
          <></>
      }
      
    </div>
  )
}

export default IndexComponent