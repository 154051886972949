import { useParams } from "react-router-dom"
import { IMAGE } from "@/assets"
import { useRoute } from "@/hook"
import "./styles.less"

const IndexComponent = (props) => {
  const { data } = props
  const { leftNode, rightNode } = data

  return (
    <div className="m_binary">
      <img src={IMAGE.binary} alt="" className="m_binary_image" />
      <div className="m_binary_node">
        <TeamItem data={leftNode} isFirst />
        <TeamItem data={rightNode} isFirst />
      </div>
      <div className="m_binary_container">
        {
          !!leftNode ? 
            <div className="m_binary_second">
              <img src={IMAGE.binary2} alt="" className="m_binary_second_image" />
              <div className="m_binary_second_node">
                <TeamItem data={leftNode.leftNode} />
                <TeamItem data={leftNode.rightNode} />
              </div>
            </div>
          : 
            <div className="m_binary_second" />
        }
        {
          !!rightNode ? 
            <div className="m_binary_second">
              <img src={IMAGE.binary2} alt="" className="m_binary_second_image" />
              <div className="m_binary_second_node">
                <TeamItem data={rightNode.leftNode} />
                <TeamItem data={rightNode.rightNode} />
              </div>
            </div>
          :
            <div className="m_binary_second" />
        }
      </div>
    </div>
  )
}

export default IndexComponent

const TeamItem = (props) => {
  const { tag } = useParams()
  const { push } = useRoute()
  const { data, isFirst } = props

  return <div className="m_binary_item_container">
    <div className="m_binary_item" onClick={() => {
      if (!data) return
      push(`/binary/${data?.userId}/${Number(tag) + 1}`)
    }}>
      <img src={data?.avatar || IMAGE.default_avatar} alt="" className="m_binary_item_avatar" />
      <p className="m_binary_item_name">{ data?.username || "-" }</p>
      {
        !data && <div className="m_binary_item_empty">
          <img src={IMAGE.empty_3} alt="" />
        </div>
      }
    </div>
    {
      !isFirst && !!data && <div className="m_binary_item_second">
        <img src={IMAGE.binary3} alt="" className="m_binary_item_second_image" />
        <div className="m_binary_item_second_node">
          {
            !!data.leftNode ?
              <img src={data.leftNode?.avatar || IMAGE.default_avatar} alt="" onClick={() => push(`/binary/${data.leftNode?.userId}/${Number(tag) + 1}`)} />
            :
              <img src={IMAGE.empty_5} alt="" />
          }
          {
            !!data.rightNode ?
              <img src={data.rightNode?.avatar || IMAGE.default_avatar} alt="" onClick={() => push(`/binary/${data.rightNode?.userId}/${Number(tag) + 1}`)}  />
            :
              <img src={IMAGE.empty_5} alt="" />
          }
        </div>
      </div>
    }
  </div>
}