import moment from "moment"
import { theString, mathematics } from "@/util"
import { IMAGE } from "@/assets"
import { useLocalStore } from "@/store"
import { useTranslation } from "react-i18next"
import "./styles.less"

const IndexComponent = (props) => {
  const { t } = useTranslation()
  const { lState } = useLocalStore()
  const { lParameter } = lState
  const { data } = props

  return (
    <div className="m_activity">
      <p className="m_activity_title">{ t("component.activity.t1") }</p>
      {
        !!data && data.length > 0 && <div className="m_activity_list">
        {
          data.map((item, index) => {
            return <div className="item" key={index}>
              <img src={
                  item.tag === "PAID" ? 
                    IMAGE.wallet_usdt_to
                  : item.tag === "DEPOSITED" ? 
                    item.asset === "Credit" ?
                      IMAGE.wallet_credit_yield
                    : item.asset === "Credit_Reserve" ?
                      IMAGE.wallet_credit_reserve_yield
                    :
                      IMAGE.wallet_usdt_from  
                  : item.tag === "EXCHANGE_OUT" ?
                      item.asset === "USDT" ?
                        IMAGE.wallet_usdt_lease
                      : item.asset === "Coupon" ?
                        IMAGE.wallet_coupon_lease
                      : 
                        IMAGE.wallet_credit_lease
                  : item.tag === "EXCHANGE_IN" ?
                      item.asset === "Coupon" ?
                        IMAGE.wallet_coupon_checkin
                      : 
                        IMAGE.wallet_credit_yield
                  : item.tag === "SWAP" ?
                    IMAGE.wallet_credit_transfer
                  : item.tag === "WITHDREW" ?
                    IMAGE.wallet_usdt_to
                  : item.icon
                } 
                alt="" 
                className="icon" 
              />
              <div className="content">
                <div className="content_row">
                  <p className="content_row_type">{
                    item.tag === "PAID" ? 
                      `${t("component.activity.t2")} ${theString.ellipsisMiddle(item.to, 12)}`
                    : item.tag === "DEPOSITED" ? 
                      item.asset === "USDT" ?
                        `${t("component.activity.t12", { username: theString.ellipsisMiddle(item.to, 12) })}`
                      :
                        `${t("component.activity.t3", { username: theString.ellipsisMiddle(item.to, 12) })}`
                    : item.tag === "EXCHANGE_OUT" ? 
                      t("component.activity.t4")
                    : item.tag === "EXCHANGE_IN" ?
                      item.asset === "Coupon" ?
                        Number(item.amount) === Number(lParameter?.inviteCouponAmount || "0") ?
                          t("component.activity.t14")
                        :
                          t("component.activity.t5")
                      :
                        t("component.activity.t6")
                    : item.tag === "SWAP" ?
                      item.asset === "USDT" ?
                        t("component.activity.t7")
                      :
                        t("component.activity.t11")
                    : item.tag === "WITHDREW" ?
                      `${t("component.activity.t13", { username: theString.ellipsisMiddle(item.to, 12) })}`
                    : 
                      item.title
                  }</p>
                  <p className="content_row_time">{ moment(item.time).format("MM.DD HH:mm") }</p>
                </div>
                <div className="content_row">
                  <p className="content_row_desc">{
                    `${mathematics.localString(item.amount)} ${
                      item.asset === "Coupon" ?
                        t("component.activity.t8")
                      : item.asset === "Credit_Reserve" ?
                        t("component.activity.t9")
                      : item.asset === "Credit" ?
                        t("component.activity.t10")
                      :
                        item.asset
                    }`
                  }</p>
                  {
                    item.status === "ERROR" ? 
                      <img src={IMAGE.fail} alt="" className="content_row_status" />
                    : item.status === "PROCESSING" ?
                      <img src={IMAGE.process} alt="" className="content_row_status" />
                    :
                      <></>
                  }
                </div>
              </div>
            </div>
          })
        }
        </div>
      }
    </div>
  )
}

export default IndexComponent