import { IMAGE } from "@/assets"
import { useTranslation } from "react-i18next"
import { useLocalStore } from "@/store"

const useConstant = () => {
  const { t } = useTranslation()
  const { lState } = useLocalStore()
  const { lParameter } = lState

  const STATUSRECORD = {
    pulling: t("hook.useConstant.t1"),
    canRelease: t("hook.useConstant.t2"),
    refreshing: t("hook.useConstant.t3"),
    complete: t("hook.useConstant.t4"),
  }

  const TABS = [{
    key: "/",
    value: t("hook.useConstant.t5"),
    icon: IMAGE.tabbar_home,
    selicon: IMAGE.tabbar_home_sel
  }, {
    key: "/wallet",
    value: t("hook.useConstant.t6"),
    icon: IMAGE.tabbar_wallet,
    selicon: IMAGE.tabbar_wallet_sel
  }, {
    key: "/rewards",
    value: t("hook.useConstant.t7"),
    icon: IMAGE.tabbar_rewards,
    selicon: IMAGE.tabbar_rewards_sel
  }, {
    key: "/profile",
    value: t("hook.useConstant.t8"),
    icon: IMAGE.tabbar_profile,
    selicon: IMAGE.tabbar_profile_sel
  }]

  const NAVBARTYPE = {
    "light": 0,
    "dark": 1
  }

  const ITEMTYPE = {
    "menu": 0,
    "info": 1,
  }

  const NETWORKS = [{
    label: "BSC",
    name: "BEP20",
    color: "#F6BB42"
  }, {
    label: "TRON",
    name: "TRC20",
    color: "#EE689D"
  // }, {
  //   label: "Polygon",
  //   name: "Polygon",
  //   color: "#967ADC"
  // }, {
  //   label: "ETH",
  //   name: "ERC20",
  //   color: "#3BAFDA"
  }]

  const WALLETS = [{
    name: "USDT",
    icon: IMAGE.usdt,
    asset: "USDT"
  }, {
    name: t("hook.useConstant.t21"),
    icon: IMAGE.credit,
    asset: "Credit",
  }, {
    name: t("hook.useConstant.t22"),
    icon: IMAGE.coupon,
    asset: "Coupon"
  }]
  
  const REWARDS = [{
    name: t("hook.useConstant.t9"),
    content: t("hook.useConstant.t10", {percentage: !!lParameter?.extraCreditRate || lParameter?.extraCreditRate === 0 ? `${lParameter?.extraCreditRate * 100}%` : "-"}),
    key: "extra",
  }, {
    name: t("hook.useConstant.t11"),
    content: t("hook.useConstant.t12", {percentage: !!lParameter?.referralCreditRate || lParameter?.referralCreditRate === 0 ? `${lParameter?.referralCreditRate * 100}%` : "-"}),
    key: "referral",
  }, {
    name: t("hook.useConstant.t13"),
    content: t("hook.useConstant.t14", {percentage: !!lParameter?.binaryCreditRate || lParameter?.binaryCreditRate === 0 ? `${lParameter?.binaryCreditRate * 100}%` : "-"}),
    key: "binary",
  }, {
    name: t("hook.useConstant.t15"),
    content: t("hook.useConstant.t16", {
      percentage: !!lParameter?.vortexCreditRate || lParameter?.vortexCreditRate === 0 ? `${lParameter?.vortexCreditRate * 100}%` : "-",
      minimum: !!lParameter?.vortexCreditLevelLimit || lParameter?.vortexCreditLevelLimit === 0 ? lParameter?.vortexCreditLevelLimit : "-",
    }),
    key: "vortex",
  }, {
    name: t("hook.useConstant.t17"),
    content: t("hook.useConstant.t18", {
      percentage: !!lParameter?.symmetryCreditRate || lParameter?.symmetryCreditRate === 0 ? `${lParameter?.symmetryCreditRate * 100}%` : "-",
      minimum: !!lParameter?.symmetryCreditValidChildren || lParameter?.symmetryCreditValidChildren === 0 ? lParameter?.symmetryCreditValidChildren : "-",
      amount: !!lParameter?.symmetryCreditIncrement || lParameter?.symmetryCreditIncrement === 0 ? lParameter?.symmetryCreditIncrement : "-",
    }),
    key: "symmetry",
  }, {
    name: t("hook.useConstant.t19"),
    content: t("hook.useConstant.t20"),
    key: "elite",
  }]

  const COUPONREWARDS = 1000

  const INVITEURL = "https://signup.starlink.bot/invite"

  return {
    STATUSRECORD,
    TABS,
    NAVBARTYPE,
    ITEMTYPE,
    NETWORKS,
    WALLETS,
    REWARDS,
    COUPONREWARDS,
    INVITEURL
  }
}

export default useConstant