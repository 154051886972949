import React from "react"
const HomePage = React.lazy(() => import("./home"))
const WalletPage = React.lazy(() => import("./wallet"))
const RewardsPage = React.lazy(() => import("./rewards"))
const ProfilePage = React.lazy(() => import("./profile"))
const LeasePage = React.lazy(() => import("./lease"))
const LeasingPage = React.lazy(() => import("./leasing"))
const StartPage = React.lazy(() => import("./start"))
const SignupPage = React.lazy(() => import("./signup"))
const LoginPage = React.lazy(() => import("./login"))
const ForgetPage = React.lazy(() => import("./forget"))
const PersonalPage = React.lazy(() => import("./personal"))
const SecurityPage = React.lazy(() => import("./security"))
const UsernamePage = React.lazy(() => import("./username"))
const EmailPage = React.lazy(() => import("./email"))
const PhonePage = React.lazy(() => import("./phone"))
const PaymentpinPage = React.lazy(() => import("./paymentpin"))
const PasswordPage = React.lazy(() => import("./password"))
const Fa1Page = React.lazy(() => import("./2fa1"))
const Fa2Page = React.lazy(() => import("./2fa2"))
const TransferPage = React.lazy(() => import("./transfer"))
const WithdrawPage = React.lazy(() => import("./withdraw"))
const VerificationPage = React.lazy(() => import("./verification"))
const DepositPage = React.lazy(() => import("./deposit"))
const InvitedPage = React.lazy(() => import("./invited"))
const BinaryPage = React.lazy(() => import("./binary"))
const RewardsDetailPage = React.lazy(() => import("./rewards_detail"))
const InvitePage = React.lazy(() => import("./invite"))
const LanguagePage = React.lazy(() => import("./language"))
const AboutPage = React.lazy(() => import("./about"))
const RewardsDescPage = React.lazy(() => import("./rewards_desc"))
const NotfoundPage = React.lazy(() => import("./404"))

export { 
  HomePage, 
  WalletPage, 
  RewardsPage, 
  ProfilePage, 
  LeasePage,
  LeasingPage, 
  StartPage, 
  SignupPage,
  LoginPage,
  ForgetPage,
  PersonalPage,
  SecurityPage,
  UsernamePage,
  EmailPage,
  PhonePage,
  PaymentpinPage,
  PasswordPage,
  Fa1Page,
  Fa2Page,
  TransferPage,
  WithdrawPage,
  VerificationPage,
  DepositPage,
  InvitedPage,
  BinaryPage,
  RewardsDetailPage,
  InvitePage,
  LanguagePage,
  AboutPage,
  RewardsDescPage,
  NotfoundPage 
}