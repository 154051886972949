/* eslint-disable no-unused-vars */
import { useLocalStore } from "@/store"
import { IMAGE } from "@/assets"
import { mathematics } from "@/util"
import { useClass } from "@/hook"
import { useTranslation } from "react-i18next"
import CopyToClipboard from "react-copy-to-clipboard"
import { Toast } from "antd-mobile"
import "./styles.less"

const IndexComponent = () => {
  const { t } = useTranslation()
  const { lState } = useLocalStore()
  const { lBinayPlan, lInfo } = lState
  const { leftTeam, rightTeam } = lBinayPlan
  const leftSmall = false
    // Number(leftTeam?.leased || "0") < Number(rightTeam?.leased || "0") ?
    //   true
    // :
    //   false

  return (
    <div className="m_team">
      <TeamItem 
        name={ t("component.team.t1") }
        icon={leftSmall ? IMAGE.team_right : IMAGE.team_left}
        _yield={mathematics.localString(leftTeam?.yieldAmount)}
        daily_leased={`${mathematics.localString(leftTeam?.dailyLeased)} FCT`}
        leased={`${mathematics.localString(leftTeam?.leased)} FCT`}
        user={mathematics.localString(leftTeam?.validMemberCount)}
        dailyNewInvited={mathematics.localString(leftTeam?.dailyNewInvited)}
        todayStaticYield={`+ ${mathematics.localString(leftTeam?.todayStaticYield)}`}
        weekly={`+ ${mathematics.standard(leftTeam?.weeklyLeased)}`}
        amount={`+ ${mathematics.localString(leftTeam?.weeklyNewInvited)}`}
        carryOver={mathematics.localString(leftTeam?.carryOver)}
        referral={lInfo?.leftInviteCode}
        type="south"
      />
      <TeamItem 
        name={ t("component.team.t2") }
        icon={leftSmall ? IMAGE.team_left : IMAGE.team_right}
        _yield={mathematics.localString(rightTeam?.yieldAmount)}
        daily_leased={`${mathematics.localString(rightTeam?.dailyLeased)} FCT`}
        leased={`${mathematics.localString(rightTeam?.leased)} FCT`}
        user={mathematics.localString(rightTeam?.validMemberCount)}
        dailyNewInvited={mathematics.localString(rightTeam?.dailyNewInvited)}
        todayStaticYield={`+ ${mathematics.localString(rightTeam?.todayStaticYield)}`}
        weekly={`+ ${mathematics.standard(rightTeam?.weeklyLeased)}`}
        amount={`+ ${mathematics.localString(rightTeam?.weeklyNewInvited)}`}
        carryOver={mathematics.localString(rightTeam?.carryOver)}
        referral={lInfo?.rightInviteCode}
        type="north"
      />
    </div>
  )
}

export default IndexComponent

const TeamItem = (props) => {
  const { t } = useTranslation()
  const { classNames } = useClass()
  const { name, icon, daily_leased, leased, user, dailyNewInvited, todayStaticYield, weekly, amount, carryOver, referral , type} = props

  return <div className={classNames([
    "m_team_item",
    icon === IMAGE.team_right && "m_team_item_small"
  ])}>
    <div className="m_team_item_head">
      <img src={icon} alt="" />
      <p>{ name }</p>
    </div>
    <TeamItemData
      label="Total Staked"
      value={leased}
    />
    <TeamItemData
      label="Members"
      value={user}
    />
    <TeamItemData
      label="New Stakes"
      value={daily_leased}
    />
    <TeamItemData
      label="New Members"
      value={dailyNewInvited}
    />
    <TeamItemData
      label="Carryover"
      value={carryOver}
    />
    {/* <TeamItemData
      label={ t("component.team.t5") }
      icon={IMAGE.team_leased_3}
      value={leased}
    />
    <TeamItemData
      label={ t("component.team.t7") }
      icon={IMAGE.user2}
      value={user}
    />
    <div className="m_team_item_line" />
    <TeamItemData
      label={ t("component.team.t4") }
      icon={IMAGE.team_leased_4}
      value={daily_leased}
    />
    <TeamItemData
      label={ t("component.team.t3") }
      icon={IMAGE.user3}
      value={dailyNewInvited}
    />
    <TeamItemData
      label={ t("component.team.t12") }
      icon={IMAGE.team_yield_3}
      value={todayStaticYield}
      valueIcon={IMAGE.logo2}
    />
    <div className="m_team_item_line" />
    <TeamItemData
      label={ t("component.team.t6") }
      icon={IMAGE.team_leased_5}
      value={weekly}
    />
    <TeamItemData
      label={ t("component.team.t11") }
      icon={IMAGE.user4}
      value={amount}
    />
    <div className="m_team_item_line" />
    {
      !!referral && <div className="m_team_item_referral">
        <p className="referral_label">{ type === "south" ? t("component.team.t8") : t("component.team.t10") }</p>
        <div className="referral_container">
          <p>{ referral }</p>
          <CopyToClipboard
            text={ referral }
            onCopy={() => Toast.show({
              icon: "success",
              content: t("page.2fa1.t7")
            })}
          >
            <img src={IMAGE.copy3} alt="" />
          </CopyToClipboard>
        </div>
      </div>
    } */}
  </div>
}

const TeamItemData = (props) => {
  const { label, icon, value, valueIcon } = props

  return <div className="m_team_item_data">
    <div className="m_team_item_data_key">
      {
        !!icon && <img src={icon} alt="" />
      }
      <p>{ label }</p>
    </div>
    <div className="m_team_item_data_value">
      <p>{ value }</p>
    {
      !!valueIcon && <img src={valueIcon} alt="" />
    }
    </div>
  </div>
}