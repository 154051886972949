/* eslint-disable no-unused-vars */
import { useLocation } from "react-router-dom"
import { Toast } from "antd-mobile"
import { useRequest } from "@/hook"
import { useLocalStore } from "@/store"
import { useTranslation } from "react-i18next"
import { theWindow } from "@/util"

const useInfo = () => {
  const { t } = useTranslation()
  const { requestNotLoadingAndError, requestNoToken, request } = useRequest()
  const { lState, lDispatch } = useLocalStore()
  const location = useLocation()
  const { lVersion } = lState
  const { pathname } = location
  
  const initUserInfo = async () => {
    const infoResp = await requestNotLoadingAndError("app/account/infoV3")
    if (!infoResp?.error) {
      lDispatch({key: "lInfo", value: infoResp})
    } 
  }

  const initParameter = async () => {
    // const parameterResp = await requestNotLoadingAndError("app/home/app_parameter")
    // if (!parameterResp?.error) {
    //   lDispatch({key: "lParameter", value: parameterResp})
    // }
  }

  const initHomeSummary = async () => {
    // const resp = await request("app/home/homepage")
    // if (!resp?.error) lDispatch({key: "lHomeSummary", value: resp})
  }

  const initBotSummary = async () => {
    // const resp = await request("app/home/bot/summary")
    // if (!resp?.error) lDispatch({key: "lBotSummary", value: resp})
  } 

  const checkUpdate = async () => {
    // const resp = await requestNoToken("app/home/app_check_update")
    // if (!resp?.error) {
    //   theWindow.updateApp(JSON.stringify(resp))
    //   pathname.startsWith("/personal") && `${resp.version}+${resp.versionCode}` === lVersion && Toast.show({
    //     icon: "success",
    //     content: t("hook.useInfo.t1")
    //   })
    // }
  }

  return {
    initUserInfo,
    initParameter,
    initHomeSummary,
    initBotSummary,
    checkUpdate
  }
}

export default useInfo