import moment from "moment"
import { mathematics } from "@/util"
import { useTranslation } from "react-i18next"
import { IMAGE } from "@/assets"
import "./styles.less"

const IndexComponent = (props) => {
  const { t } = useTranslation()
  const { data } = props

  return (
    <div className="m_bot">
      <div className="m_bot_tabs">
        <div className="tab_item">
          <img src={IMAGE.date} alt="" />
          <p>{ t("component.bot.t3") }</p>
        </div>
        <div className="tab_item">
          <img src={IMAGE.team_leased} alt="" />
          <p>{ t("component.bot.t2") }</p>
        </div>
        <div className="tab_item">
          <p>{ t("component.bot.t1") }</p>
          <img src={IMAGE.rewards_2} alt="" />
        </div>
      </div>
      <div className="m_bot_list">
      {
        data.map((item, index) => {
          return <div className="item_container" key={index}>
            <div className="content">
              <img src={IMAGE.date_2} alt="" />
              <p>{ moment(item.date).format("MM.DD") }</p>
            </div>
            <div className="content">
              <p>{ mathematics.standard(item.leased) }</p>
            </div>
            <div className="content">
              <p>{ mathematics.localString(item.credit) }</p>
              <img src={IMAGE.logo2} alt="" />
            </div>
          </div>
        })
      }
      </div>
    </div>
  )
}

export default IndexComponent