/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import * as echarts from "echarts"
import moment from "moment"
import { theWindow, mathematics } from "@/util"
import { useRequest, useClass } from "@/hook"
import { useTranslation } from "react-i18next"
import { IMAGE } from "@/assets"
import "./styles.less"

const IndexComponent = (props) => {
  const { className } = props
  const { t } = useTranslation()
  const location = useLocation()
  const { pathname } = location
  const { request } = useRequest()
  const { classNames } = useClass()
  const [seriesData, setSeriesData] = useState([])
  const [xAxisData, setXAxisData] = useState([])

  useEffect(() => {
    async function getData() {
      if (pathname === "/") {
        const resp = await request("app/home/trafficTrend", {
          quantity: 60
        })
        if (!resp?.error) {
          const _seriesData = resp?.map(subData => subData.data)
          const _xAxisData = resp?.map(subData => {
            const timestamp = subData.time
            const time = moment(timestamp)
            return time.format('HH:mm')
          })
          setSeriesData(_seriesData)
          setXAxisData(_xAxisData)
        }
      } else if (pathname === "/lease") {
        const resp = await request("app/home/botTrafficTrend", {
          quantity: 60
        })
        if (!resp?.error) {
          const _seriesData = resp?.map(subData => subData.data)
          const _xAxisData = resp?.map(subData => {
            const timestamp = subData.time
            const time = moment(timestamp)
            return time.format('HH:mm')
          })
          setSeriesData(_seriesData)
          setXAxisData(_xAxisData)
        }
      }
    }
    getData()
  }, [pathname])

  // useEffect(() => {
  //   const interval = setTimeout(function() {
  //     if (!!seriesData) {
  //       const _seriesData = seriesData.concat()
  //       const _xAxisData = xAxisData.concat()
  //       setSeriesData(_seriesData)
  //       setXAxisData(_xAxisData)
  //     }
  //   }, 1000) 
  //   return () => {
  //     clearInterval(interval)
  //   }
  // }, [seriesData])

  useEffect(() => {
    const chartDom = document.getElementById("echartID")
    let myChart = echarts.getInstanceByDom(chartDom)
    if (!myChart) myChart = echarts.init(chartDom)
    const option = {
      grid: {
        // left: 0,
        // right: 5,
        // top: 0,
        // bottom: 0,
        // containLabel: false
        left: pathname === "/lease" ? 46 : 36,
        right: 16,
        top: 10,
        bottom: 20,
      },
      xAxis: {
        type: "category",
        data: xAxisData,
        // show: false,
        boundaryGap: false,
        axisLabel: {
          fontSize: 11,
          color: function (value, index) {
            return theWindow.getComputedStyle("--content_secondary_color")
          },
        },
        axisLine: {
          lineStyle: {
            color: theWindow.getComputedStyle("--border_color")
          }
        },
        axisTick: {
          lineStyle: {
            color: theWindow.getComputedStyle("--border_color")
          }
        },
        splitLine: {
          show: false
        }
      },
      yAxis: {
        type: "value",
        // show: false,
        scale: true, 
        axisLabel: {
          fontSize: 11,
          color: function (value, index) {
            return index === 0 ? "transparent" : theWindow.getComputedStyle("--content_secondary_color")
          },
        },
        splitLine: {
          lineStyle: {
            color: theWindow.getComputedStyle("--border_color")
          }
        },
        boundaryGap: [0, 0], 
      },
      series: [
        {
          data: seriesData,
          type: "line",
          showSymbol: false,
          areaStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: theWindow.getComputedStyle("--content_positive_color")
              },
              {
                offset: 1,
                color: 'rgba(55, 188, 155, 0.00)'
              }
            ])
          },
          clip: true,
          smooth: true,
          lineStyle: {
            color: theWindow.getComputedStyle("--content_positive_color"),
            width: 2.5
          },
          markPoint: {
            data: [{
              yAxis: seriesData.length === 0 ? 0 : seriesData[seriesData.length - 1],
              xAxis: xAxisData.length === 0 ? 0 : xAxisData[xAxisData.length - 1],
              symbol: 'circle',
              symbolSize: 8,
              itemStyle: {
                color: theWindow.getComputedStyle("--content_positive_color"),
                borderWidth: 6,
                borderColor: 'rgba(55, 188, 155, 0.12)'
              },
              animation: true,
              animationDuration: 1000
            }]
          }
        },
      ]
    }
    myChart.setOption(option)
  }, [xAxisData, seriesData])

  return (
    <div className={classNames([
      "m_kline",
      !!className && className
    ])}>
      <div className="m_kline_head">
        <p className="title">{ 
          pathname === "/" ? 
            t("component.kline.t1") 
          : pathname === "/lease" ?
            t("component.kline.t2")
          : 
            ""
        }</p>
        {
          <div className="extra">
            <p>{`${mathematics.localString(seriesData[seriesData.length - 1])} Gb`}</p>
            <img src={IMAGE.star_2} alt="" />
          </div>
        }
      </div>
      <div className="m_kline_echart_container" id="echartID" />
      {/* <div className="m_kline_tabs">
      {
        ["1D", "1W", "1M", "1Y"].map((item, index) => {
          return <div className={classNames([
            "tab_item",
            index === tabIndex && "tab_item_sel"
          ])} key={index} onClick={() => setTabIndex(index)}>{ item }</div>
        })
      }
      </div> */}
    </div>
  )
}

export default IndexComponent