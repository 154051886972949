/* eslint-disable no-unused-vars */
import { Suspense } from "react"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import { CSSTransition, TransitionGroup } from "react-transition-group"
import { 
  HomePage, 
  WalletPage, 
  RewardsPage, 
  ProfilePage, 
  LeasePage,
  LeasingPage, 
  StartPage, 
  SignupPage,
  LoginPage,
  ForgetPage,
  PersonalPage,
  SecurityPage,
  UsernamePage,
  EmailPage,
  PhonePage,
  PaymentpinPage,
  PasswordPage,
  Fa1Page,
  Fa2Page,
  TransferPage,
  WithdrawPage,
  VerificationPage,
  DepositPage,
  InvitedPage,
  BinaryPage,
  RewardsDetailPage,
  LanguagePage,
  InvitePage,
  AboutPage,
  RewardsDescPage,
} from "@/page"
import { MTabbar, MWatch, MError } from "@/component"
import "@/i18n"
import "./App.less"

const App = () => {
  
  return (
    <Router>
      <Route render={({ location }) => (
        <TransitionGroup>
          <CSSTransition
            key={location.key}
            timeout={300}
            classNames="fade"
          >
            <Suspense fallback={<div></div>}>
              <MError>
                <Switch location={location}>
                  <MWatch>
                    {/* <Route exact path="/" component={HomePage} /> */}
                    {/* <Route path="/wallet" component={WalletPage} /> */}
                    <Route path="/rewards" component={RewardsPage} />
                    {/* <Route path="/profile" component={ProfilePage} /> */}
                    {/* <Route path="/lease" component={LeasePage} /> */}
                    {/* <Route path="/leasing" component={LeasingPage} /> */}
                    {/* <Route path="/start" component={StartPage} /> */}
                    {/* <Route path="/signup" component={SignupPage} /> */}
                    {/* <Route path="/login" component={LoginPage} /> */}
                    {/* <Route path="/forget" component={ForgetPage} /> */}
                    {/* <Route path="/personal" component={PersonalPage} /> */}
                    {/* <Route path="/security" component={SecurityPage} /> */}
                    {/* <Route path="/username" component={UsernamePage} /> */}
                    {/* <Route path="/email" component={EmailPage} /> */}
                    {/* <Route path="/phone" component={PhonePage} /> */}
                    {/* <Route path="/paymentpin" component={PaymentpinPage} /> */}
                    {/* <Route path="/password" component={PasswordPage} /> */}
                    {/* <Route path="/2fa1" component={Fa1Page} /> */}
                    {/* <Route path="/2fa2/:code" component={Fa2Page} /> */}
                    {/* <Route path="/transfer" component={TransferPage} /> */}
                    {/* <Route path="/withdraw" component={WithdrawPage} /> */}
                    {/* <Route path="/verification/:parameter/:type" component={VerificationPage} /> */}
                    {/* <Route path="/deposit" component={DepositPage} /> */}
                    <Route path="/invited" component={InvitedPage} />
                    <Route path="/binary/:id/:tag" component={BinaryPage} />
                    <Route path="/rewardsDetail/:type" component={RewardsDetailPage} />
                    {/* <Route path="/share" component={InvitePage} /> */}
                    {/* <Route path="/language" component={LanguagePage} /> */}
                    {/* <Route path="/about" component={AboutPage} /> */}
                    <Route path="/rewardsDesc/:type/:date" component={RewardsDescPage} />
                  </MWatch>
                </Switch>
              </MError>
            </Suspense>
          </CSSTransition>
        </TransitionGroup>
      )} />
      {/* <MTabbar /> */}
    </Router>
  )
}

export default App