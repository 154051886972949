import React from "react"
import { withRouter } from "react-router-dom"
import { Button } from "antd-mobile"
import { withTranslation } from "react-i18next"
import "./styles.less"

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  render() {
    const { t } = this.props
    if (this.state.hasError) {
      return <div className="m_error">
        <p className="m_error_content">{ t("component.error.t1") }</p>
        <Button className="m_error_button" onClick={() => this.props.history.push("/")}>{ t("component.error.t2") }</Button>
      </div>
    }

    return this.props.children; 
  }
}

export default withTranslation()(withRouter(ErrorBoundary))