import { useConstant, useRoute, useClear } from "@/hook"
import { IMAGE } from "@/assets"
import "./styles.less"

const IndexComponent = (props) => {
  const { icon, label, value, route, type, _event } = props
  const { push } = useRoute()
  const { ITEMTYPE } = useConstant()
  const { cleanUserInfo } = useClear()

  return (
    type === ITEMTYPE.menu ? <div className="m_item" onClick={() => {
      if (!!_event) {
        _event()
      } else {
        route === "start" && cleanUserInfo()
        !!route && push(`/${route}`)
      }
    }}>
      {
        !!icon && <img src={icon} alt="" className="m_item_icon" />
      }
      <p className="m_item_label">{ label }</p>
      <img src={IMAGE.arrow_right_gray} alt="" className="m_item_arrow" />
    </div> :
    type === ITEMTYPE.info ? <div className="m_item" onClick={() => {
      if (!!_event) {
        _event()
      } else {
        !!route && push(`/${route}`)
      }
      
    }}>
      <div className="m_item_info">
        <p className="m_item_label">{ label }</p>
        <p className="m_item_content">{ value }</p>
      </div>
      <img src={IMAGE.arrow_right_gray} alt="" className="m_item_arrow" />
    </div> :
    <></>
  )
}
IndexComponent.displayName = "m_list_item"

export default IndexComponent