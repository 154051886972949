import React from "react"
import { useTranslation } from "react-i18next"
import { Button } from "antd-mobile"
import { useRoute } from "@/hook"
import { theWindow } from "@/util"
import "./styles.less"

const IndexComponent = React.memo((props) => {
  const { t } = useTranslation()
  const { noneTitle, icon, content, buttonName, route } = props
  const { push } = useRoute()

  return (
    <div className="m_nodata">
      <img src={icon} alt="" className="m_nodata_icon" />
      {
        !noneTitle && <p className="m_nodata_title">{t("component.nodata.t1")}</p>
      }
      {
        !!content && <p className="m_nodata_content">{ content }</p>
      }
      {
        !!buttonName && <Button className="m_nodata_button" onClick={() => {
          if (!route) return
          if (route === "share") {
            theWindow.gotoInvite()
          } else {
            push(`/${route}`)
          }
        }}>{ buttonName }</Button>
      }
    </div>
  )
})

export default IndexComponent