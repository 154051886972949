import { storage } from "@/util"

const reducer = (state, action) => {
  storage.setl(action.key, action.value)
  switch(action.key) {
    case "lToken":
      return { ...state, lToken: action.value }
    case "lInfo":
      return { ...state, lInfo: action.value }
    case "lParameter":
      return { ...state, lParameter: action.value }
    case "lHomeSummary":
      return { ...state, lHomeSummary: action.value }
    case "lBotSummary":
      return { ...state, lBotSummary: action.value }
    case "lRewardSummary":
      return { ...state, lRewardSummary: action.value }
    case "lBinayPlan":
      return { ...state, lBinayPlan: action.value }
    case "lVersion":
      return { ...state, lVersion: action.value }
    default:
      return state
  }
}

export default reducer