/* eslint-disable no-unused-vars */
import { useRoute, useConstant } from "@/hook"
import { mathematics } from "@/util"
import { useLocalStore } from "@/store"
import { useTranslation } from "react-i18next"
import { IMAGE } from "@/assets"
import "./styles.less"

const IndexComponent = (props) => {
  const { lState } = useLocalStore()
  const { lRewardSummary } = lState

  return (
    <div className="m_summary">
      {/* <SummaryItem type="lease" value={lRewardSummary?.staticRewards} /> */}
      {/* <SummaryItem type="extra" value={lRewardSummary?.extraCredits} /> */}
      <SummaryItem type="referral" value={lRewardSummary?.referralBonus} />
      {/* <SummaryItem type="binary" value={lRewardSummary?.binaryBonus} /> */}
      {/* <SummaryItem type="vortex" value={lRewardSummary?.vortexBonus} /> */}
      <SummaryItem type="binary" value={lRewardSummary?.binaryBonus} />
      {/* <SummaryItem type="elite" value={lRewardSummary?.eliteBonus} /> */}
    </div>
  )
}

export default IndexComponent

const SummaryItem = (props) => {
  const { t } = useTranslation()
  const { REWARDS } = useConstant()
  const { push } = useRoute()
  const { type, value } = props

  return (
    <div className="m_summary_item" onClick={() => {
      if (type === "lease") {
        push("lease")
      } else {
        push(`/rewardsDetail/${type}`)
      }
    }}>
      <p className="m_summary_item_key">{ REWARDS.find(obj => obj.key === type)?.name || t("component.lifetime.t6") }</p>
      <p className="m_summary_item_value">{!!value && Number(value) > 0 ? `+ ${mathematics.localString(value)} FCC` : mathematics.localString(value)}</p>
      <img src={IMAGE.arrow_right_white} alt="" className="m_summary_item_arrow" />
    </div>
  )
}