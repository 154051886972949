import { mathematics } from "@/util"
import { useTranslation } from "react-i18next"
import "./styles.less"

const IndexComponent = (props) => {
  const { t } = useTranslation()
  const { backgroundColor, icon, bgImage, name, amount, extraDom, reserve } = props

  return (
    <div className="m_card">
      <div className="m_card_container" style={{ backgroundColor: backgroundColor }}>
        <div className="logo_title">
          <img src={icon} alt="" />
          <p>{ name }</p>
        </div>
        <div className="amount_extra">
          <p className="amount">{ mathematics.localString(amount) }</p>
          {
            extraDom
          }
        </div>
      </div>
      {
        (!!reserve || reserve === 0) && <div className="m_card_reserve_container">
          <p className="key">{ t("component.card.t1") }</p>
          <p className="value">{ mathematics.localString(reserve) }</p>
        </div>
      }
      <img src={bgImage} alt="" className="m_card_bg" />
    </div>
  )
}

export default IndexComponent