import { mathematics } from "@/util"
import { useTranslation } from "react-i18next"
import { Button } from "antd-mobile"
import { useLocalStore } from "@/store"
import { ProgressBar } from "antd-mobile"
import { useRoute } from "@/hook"
import { IMAGE } from "@/assets"
import "./styles.less"

const IndexComponent = () => {
  const { t } = useTranslation()
  const { lState } = useLocalStore()
  const { push } = useRoute()
  const { lBotSummary, lInfo } = lState

  return (
    <div className="m_bandwidth">
     <div className="m_bandwidth_head">
        <p className="name">{ t("component.bandwidth.t1") }</p>
        <img src={IMAGE.bandwidth} alt="" />
        <p className="amount">{ mathematics.standard(lBotSummary?.bandwidth) }<span>{`/${mathematics.standard(lBotSummary?.totalBandwidth)}`}</span></p>
      </div>
      <ProgressBar percent={
        !lBotSummary || Number(lBotSummary?.bandwidth) === 0 || Number(lBotSummary?.totalBandwidth) === 0 ?
          0
        :
          Math.max((Number(lBotSummary?.bandwidth) / Number(lBotSummary?.totalBandwidth)) * 100, 0.5)
        } 
        className="m_bandwidth_process_bar"
      /> 
      <Button className="m_bandwidth_more" onClick={() => {
        if (!lInfo.hasPaymentPassword) {
          push("/paymentpin")
        } else {
          push("/leasing")
        }
      }}>
        <img src={IMAGE.add} alt="" />{t("component.bandwidth.t2")}
      </Button>
    </div>
  )
}

export default IndexComponent