/* eslint-disable no-unused-vars */
import { mathematics } from "@/util"
import { useTranslation } from "react-i18next"
import { useLocalStore } from "@/store"
import { ProgressBar } from "antd-mobile"
import { useRoute } from "@/hook"
import { IMAGE } from "@/assets"
import "./styles.less"

const IndexComponent = () => {
  const { t } = useTranslation()
  const { push } = useRoute()
  const { lState } = useLocalStore()
  const { lHomeSummary, lBotSummary } = lState

  return (
    <div className="m_lifetime" onClick={() => push("/lease")}>
      <div className="m_lifetime_head">
        <p className="name">{t("component.lifetime.t1")}</p>
        <img src={IMAGE.logo2} alt="" />
        <p className="amount">{ mathematics.localString(lHomeSummary?.totalYield) }</p>
      </div>
      <div className="m_lifetime_content">
        <div className="content_keys">
          <p>{t("component.lifetime.t2")}</p>
          <p>{t("component.lifetime.t3")}</p>
          <p>{t("component.lifetime.t4")}</p>
        </div>
        <div className="content_values">
          <p>{ mathematics.localString(lHomeSummary?.totalStaticYield) }</p>
          <p>{ mathematics.localString(lHomeSummary?.totalDynamicYield) }</p>
          <p>{`${
          !lBotSummary || Number(lBotSummary?.actualProduceCredit) === 0 || Number(lBotSummary?.expectedProduceCredit) === 0 ?
            0
          :
            Math.min(Math.ceil((Number(lBotSummary?.actualProduceCredit) / Number(lBotSummary?.expectedProduceCredit)) * 300), 300)
          }%`}</p>
        </div>
      </div>
      <ProgressBar percent={
        !lBotSummary || Number(lBotSummary?.actualProduceCredit) === 0 || Number(lBotSummary?.expectedProduceCredit) === 0 ?
          0
        :
          Math.max((Number(lBotSummary?.actualProduceCredit) / Number(lBotSummary?.expectedProduceCredit)) * 100, 0.5)
      } className="m_lifetime_process_bar" />
    </div>
  )
}

export default IndexComponent

const LifetimeRewardsComponent = () => {
  const { t } = useTranslation()
  const { lState } = useLocalStore()
  const { lRewardSummary } = lState

  return <div className="m_lifetime_rewards">
    <div className="lifetime_content">
      <p className="lifetime_name">{t("component.lifetime.t1")}</p>
      {/* <div className="lifetime_desc">
        <p>{ mathematics.localString(lRewardSummary?.totalRewards) }</p>
        <img src={IMAGE.logo2} alt="" />
      </div> */}
    </div>
    {/* <ProgressCircle percent={
      !lBotSummary || Number(lBotSummary?.actualProduceCredit) === 0 || Number(lBotSummary?.expectedProduceCredit) === 0 ?
        0
      :
        Math.max((Number(lBotSummary?.actualProduceCredit) / Number(lBotSummary?.expectedProduceCredit)) * 100, 0.5)
    } className="lifetime_process_circle">
      <p className="lifetime_process_circle_key">{t("component.lifetime.t5")}</p>
      <p className="lifetime_process_circle_value">
        {`${
        !lBotSummary || Number(lBotSummary?.actualProduceCredit) === 0 || Number(lBotSummary?.expectedProduceCredit) === 0 ?
          0
        :
          Math.min(Math.ceil((Number(lBotSummary?.actualProduceCredit) / Number(lBotSummary?.expectedProduceCredit)) * 300), 300)
        }%`}
      </p>
    </ProgressCircle> */}
  </div>
}

const NetworkRewardsComponent = () => {
  const { t } = useTranslation()
  const { lState } = useLocalStore()
  const { push } = useRoute()
  const { lHomeSummary } = lState

  return <div className="m_network_rewards" onClick={() => push("/lease")}>
    <p className="m_network_rewards_name">{t("component.lifetime.t6")}</p>
    <div className="m_network_rewards_content">
      <p className="today_amount">{ mathematics.localString(lHomeSummary?.totalStaticYield) }</p>
      <p className="total_amount"><span>{t("component.lifetime.t7")}</span> {`+${mathematics.localString(lHomeSummary?.todayStaticYield)}`}</p>
      <img src={IMAGE.arrow_right_white} alt="" className="arrow" />
    </div>
    <div className="m_network_rewards_bg">
      <img src={IMAGE.bg4} alt="" className="bg1" />
      <img src={IMAGE.bg3} alt="" className="bg2" />
    </div>
  </div>
}

export {
  LifetimeRewardsComponent,
  NetworkRewardsComponent
}