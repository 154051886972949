/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react"
import { useLocation } from "react-router-dom"
import { useConstant, useRoute, useClass, useInfo } from "@/hook"
import { useLocalStore } from "@/store"
import "./styles.less"

const IndexComponent = React.memo(() => {
  const location = useLocation()
  const { pathname } = location
  const { classNames } = useClass()
  const { TABS } = useConstant()
  const { replace } = useRoute()
  const { lState, lDispatch } = useLocalStore()
  const { initUserInfo, initParameter, checkUpdate } = useInfo()
  const { lToken } = lState

  useEffect(() => {
    if (!!lToken) {
      initUserInfo() 
      initParameter()
    }
  }, [lToken])

  useEffect(() => {
    checkUpdate()
  }, [])

  useEffect(() => {
    const handleGetVersion = (obj) => {
      if (!!obj && !!obj.detail && obj.detail !== "null") {
        lDispatch({key: "lVersion", value: obj.detail})
      }
    }
    window.addEventListener("getVersion", handleGetVersion)

    return () => {
      window.removeEventListener("getVersion", handleGetVersion)
    }
  }, [])

  window.versionCallback = (_version) => {
    const event = new CustomEvent("getVersion", { detail: _version })
    window.dispatchEvent(event)
  }

  return (
    <div className={classNames([
      "m_tabbar",
      !TABS.some(item => item.key === pathname) && "m_tabbar_hidden"
    ])}>
    {
      TABS.map((tab, index) => {
        return <div className="m_tabbar_item" key={index} onClick={() => replace(tab.key)}>
          <img src={pathname === tab.key ? tab.selicon : tab.icon} alt="" className="m_tabbar_item_icon" />
          <p className={classNames([
            "m_tabbar_item_name",
            pathname === tab.key && "m_tabbar_item_name_primary"
          ])}>{tab.value}</p>
        </div>
      })
    }
    </div>
  )
})

export default IndexComponent