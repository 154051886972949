import tabbar_home from "./assets/tabbar_home.png"
import tabbar_home_sel from "./assets/tabbar_home_sel.png"
import tabbar_wallet from "./assets/tabbar_wallet.png"
import tabbar_wallet_sel from "./assets/tabbar_wallet_sel.png"
import tabbar_rewards from "./assets/tabbar_rewards.png"
import tabbar_rewards_sel from "./assets/tabbar_rewards_sel.png"
import tabbar_profile from "./assets/tabbar_profile.png"
import tabbar_profile_sel from "./assets/tabbar_profile_sel.png"
import profile_personal from "./assets/profile_personal.png"
import profile_security from "./assets/profile_security.png"
import profile_invite from "./assets/profile_invite.png"
import profile_support from "./assets/profile_support.png"
import profile_logout from "./assets/profile_logout.png"
import profile_language from "./assets/profile_language.png"
import profile_about from "./assets/profile_about.png"
import wallet_credit_lease from "./assets/wallet_credit_lease.png"
import wallet_credit_transfer from "./assets/wallet_credit_transfer.png"
import wallet_credit_yield from "./assets/wallet_credit_yield.png"
import wallet_credit_reserve_yield from "./assets/wallet_credit_reserve_yield.png"
import wallet_coupon_lease from "./assets/wallet_coupon_lease.png"
import wallet_coupon_checkin from "./assets/wallet_coupon_checkin.png"
import wallet_usdt_lease from "./assets/wallet_usdt_lease.png"
import wallet_usdt_from from "./assets/wallet_usdt_from.png"
import wallet_usdt_to from "./assets/wallet_usdt_to.png"
import arrow_right_white from "./assets/arrow_right_white.png"
import arrow_right_gray from "./assets/arrow_right_gray.png"
import arrow_left_black from "./assets/arrow_left_black.png"
import arrow_left_white from "./assets/arrow_left_white.png"
import arrow_bottom_black from "./assets/arrow_bottom_black.png"
import arrow_bottom_black2 from "./assets/arrow_bottom_black2.png"
import arrow_bottom_blue from "./assets/arrow_bottom_blue.png"
import team_left from "./assets/team_left.png"
import team_right from "./assets/team_right.png"
import team_yield from "./assets/team_yield.png"
import team_daily_leased from "./assets/team_daily_leased.png"
import team_leased from "./assets/team_leased.png"
import team_yield_1 from "./assets/team_yield_1.png"
import team_daily_leased_1 from "./assets/team_daily_leased_1.png"
import team_leased_1 from "./assets/team_leased_1.png"
import team_yield_2 from "./assets/team_yield_2.png"
import team_yield_3 from "./assets/team_yield_3.png"
import team_daily_leased_2 from "./assets/team_daily_leased_2.png"
import team_leased_2 from "./assets/team_leased_2.png"
import team_leased_3 from "./assets/team_leased_3.png"
import team_leased_4 from "./assets/team_leased_4.png"
import team_leased_5 from "./assets/team_leased_5.png"
import team_weekly from "./assets/team_weekly.png"
import location_default from "./assets/location_default.png"
import location_US from "./assets/location_US.png"
import location_GB from "./assets/location_GB.png"
import location_DE from "./assets/location_DE.png"
import location_SE from "./assets/location_SE.png"
import location_ES from "./assets/location_ES.png"
import location_ZA from "./assets/location_ZA.png"
import location_JP from "./assets/location_JP.png"
import location_KR from "./assets/location_KR.png"
import location_SG from "./assets/location_SG.png"
import location_AU from "./assets/location_AU.png"
import location_CL from "./assets/location_CL.png"
import empty_1 from "./assets/empty_1.png"
import empty_2 from "./assets/empty_2.png"
import empty_3 from "./assets/empty_3.png"
import empty_4 from "./assets/empty_4.png"
import empty_5 from "./assets/empty_5.png"
import logo from "./assets/logo.png"
import logo2 from "./assets/logo2.png"
import logo_name from "./assets/logo_name.png"
import logo_name2 from "./assets/logo_name2.png"
import checkin from "./assets/checkin.png"
import message from "./assets/message.png"
import starlink from "./assets/starlink.png"
import default_avatar from "./assets/default_avatar.png"
import edit from "./assets/edit.png"
import copy from "./assets/copy.png"
import copy2 from "./assets/copy2.png"
import copy3 from "./assets/copy3.png"
import eye_close from "./assets/eye_close.png"
import eye_open from "./assets/eye_open.png"
import exchange from "./assets/exchange.png"
import credit from "./assets/credit.png"
import coupon from "./assets/coupon.png"
import coupon_2 from "./assets/coupon_2.png"
import usdt from "./assets/usdt.png"
import transferin from "./assets/transferin.png"
import transferout from "./assets/transferout.png"
import fail from "./assets/fail.png"
import process from "./assets/process.png"
import tick from "./assets/tick.png"
import tick2 from "./assets/tick2.png"
import bot_on from "./assets/bot_on.png"
import bot_off from "./assets/bot_off.png"
import binary from "./assets/binary.png"
import binary2 from "./assets/binary2.png"
import binary3 from "./assets/binary3.png"
import back from "./assets/back.png"
import rewards from "./assets/rewards.png"
import rewards_2 from "./assets/rewards_2.png"
import rewards_left from "./assets/rewards_left.png"
import rewards_right from "./assets/rewards_right.png"
import qr from "./assets/qr.png"
import leasing_1 from "./assets/leasing_1.png"
import leasing_2 from "./assets/leasing_2.png"
import star from "./assets/star.png"
import star_2 from "./assets/star_2.png"
import wifi from "./assets/wifi.png"
import wifi2 from "./assets/wifi2.png"
import connect from "./assets/connect.png"
import bandwidth from "./assets/bandwidth.png"
import add from "./assets/add.png"
import name from "./assets/name.png"
import time from "./assets/time.png"
import banner_zh from "./assets/banner_zh.png"
import banner_en from "./assets/banner_en.png"
import close from "./assets/close.png"
import close2 from "./assets/close2.png"
import code from "./assets/code.png"
import bg from "./assets/bg.png"
import bg2 from "./assets/bg2.png"
import bg3 from "./assets/bg3.png"
import bg4 from "./assets/bg4.png"
import bg5 from "./assets/bg5.png"
import bg6 from "./assets/bg6.png"
import max from "./assets/max.png"
import date from "./assets/date.png"
import date_2 from "./assets/date_2.png"
import user from "./assets/user.png"
import user2 from "./assets/user2.png"
import user3 from "./assets/user3.png"
import user4 from "./assets/user4.png"
import invited_list from "./assets/invited_list.png"
import role1 from "./assets/role1.png"
import role2 from "./assets/role2.png"
import search from "./assets/search.png"
import calendar from "./assets/calendar.png"
import invite from "./assets/invite.png"
import ambassador from "./assets/ambassador.png"
import counselor from "./assets/counselor.png"
import evangelist from "./assets/evangelist.png"
import member from "./assets/member.png"
import minister from "./assets/minister.png"

const IMAGE = {
  tabbar_home, tabbar_home_sel, tabbar_wallet, tabbar_wallet_sel, tabbar_rewards, tabbar_rewards_sel, tabbar_profile, tabbar_profile_sel,
  profile_personal, profile_security, profile_invite, profile_support, profile_logout, profile_language, profile_about,
  wallet_credit_lease, wallet_credit_transfer, wallet_credit_yield, wallet_credit_reserve_yield, wallet_coupon_lease, wallet_coupon_checkin, wallet_usdt_lease, wallet_usdt_from, wallet_usdt_to,
  arrow_right_white, arrow_right_gray, arrow_left_black, arrow_left_white, arrow_bottom_black, arrow_bottom_black2, arrow_bottom_blue,
  team_left, team_right, team_yield, team_daily_leased, team_leased, team_weekly, team_yield_1, team_daily_leased_1, team_leased_1, team_yield_2, team_daily_leased_2, team_leased_2, team_leased_3, team_leased_4, team_leased_5, team_yield_3,
  location_default, location_US, location_GB, location_DE, location_SE, location_ES, location_ZA, location_JP, location_KR, location_SG, location_AU, location_CL,
  empty_1, empty_2, empty_3, empty_4, empty_5,
  logo, logo2, logo_name, logo_name2, checkin, starlink, default_avatar, edit, copy, copy2, copy3, eye_close, eye_open, exchange, credit, coupon, coupon_2, usdt, transferin, transferout, fail, process, tick, tick2, bot_on, bot_off, binary, binary2, binary3, back, rewards, rewards_2,rewards_left, rewards_right, qr, message, leasing_1, leasing_2, star, star_2, wifi, wifi2, connect, bandwidth, add, name, time, banner_zh, banner_en, close, close2, code, bg, bg2, bg3, bg4, bg5, bg6, max, date, date_2, user, user2, user3, user4, invited_list, role1, role2, search, calendar, invite,
  ambassador, counselor, evangelist, member, minister
}

export default IMAGE